import React from 'react'
import { PageProps, Script } from 'gatsby'

import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import { PageContext } from 'src/types'
import PageContainer from "../../../components/PageContainer/PageContainer";
import Header from "../../../components/Header/Header";
import { getNavigationItems } from "../../../lib/navigation";
import { useLocale } from "../../../lib/I18nContext";
import { useTranslate } from "react-polyglot";

type DataProps = Record<string, never>

const OrgPage: React.FC<PageProps<DataProps, PageContext>> = ({
  pageContext,
  data,
}) => {
  const locale = useLocale()
  const t = useTranslate()

  return (
    <Layout
      pageContext={pageContext}
      hideSiteHeader
    >
      <Head pageContext={pageContext} />
      <PageContainer>
        <Header
          canonicalPath={pageContext.canonicalPath}
          items={getNavigationItems(t, locale)}
          hideLocaleDropdown
        />
        <div
          // @ts-ignore
          name="termly-embed"
          data-id="369f6ac8-099f-406e-8c44-60a434b2c5b4"
        />
      </PageContainer>
      <Script
        id="termly-load"
        type="text/javascript"
      >{`(function (d, s, id
        ) {
          var js, tjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://app.termly.io/embed-policy.min.js";
          tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'
        ))
        ;`}</Script>
    </Layout>
  )
}

export default OrgPage
